<template>
  <div
    class="product-search-row d-flex justify-space-between align-center w-100 py-1"
    @click="goToProductDetail"
  >
    <v-list-item-avatar width="70" height="70" class="my-0 mr-1">
      <img
        :src="product.mediaURL"
        :alt="product.name"
        onerror="this.onerror=null;this.src='/no-icon.png'"
      />
    </v-list-item-avatar>
    <v-list-item-content class="default--text pa-0 pa-sm-2">
      <span class="product-name text-caption">
        {{ product.name }}
      </span>
      <v-list-item-subtitle
        class="product-short-descr font-weight-semibold text-uppercase text-caption"
      >
        {{ product.shortDescr }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action class="w-100 flex-row align-center mx-0">
      <div class="price d-flex flex-column align-center ml-1 mr-2">
        <ProductPrice
          v-if="product.priceDisplay || product?.warehousePromo?.view?.MPieces"
          :product="product"
          :showStandardPrice="showStandardPrice"
          :showStandardDisplay="showStandardDisplay"
        />
      </div>
      <v-btn
        color="primary"
        depressed
        height="40"
        class="add-to-cart"
        @click.stop.prevent="addToCart(quantity)"
      >
        <v-icon>$cart</v-icon>
      </v-btn>
    </v-list-item-action>
  </div>
</template>
<style lang="scss">
.product-search-row {
  border-bottom: 1px solid var(--v-grey-lighten1);
  .product-name {
    color: var(--v-default-base) !important;
  }
  .product-short-descr {
    font-weight: 600 !important;
    color: var(--v-default-base) !important;
  }
  .add-to-cart {
    margin-left: 8px;
    border-radius: 100%;
    color: #ffffff !important;
    width: 50px !important;
    max-width: 50px !important;
    min-width: 50px !important;
    height: 50px !important;
  }
  .product-name {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .product-price {
    .old-price {
      font-size: 0.875rem;
      line-height: 0.875;
    }
    .cur-price {
      font-size: 1.15rem;
    }
  }
  .v-list-item__action {
    width: fit-content;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";

import productMixin from "~/mixins/product";

export default {
  name: "ProductSearchRow",
  mixins: [productMixin],
  components: { ProductPrice },
  props: {
    product: { type: Object, required: true }
  },
  computed: {
    showStandardDisplay() {
      let result =
        this.product.warehousePromo &&
        this.product.warehousePromo.view &&
        this.product.warehousePromo.view.MPieces != null;
      if (result != true) {
        result = false;
      }
      return result;
    },
    showStandardPrice() {
      let result =
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId &&
          this.product.warehousePromo.warehousePromoTypeId != 1 &&
          this.product.warehousePromo.warehousePromoTypeId != 2 &&
          this.product.warehousePromo.warehousePromoTypeId != 5 &&
          this.product.warehousePromo.warehousePromoTypeId != 6) ||
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId &&
          this.product.warehousePromo.warehousePromoTypeId == 6 &&
          this.product.warehousePromo.view &&
          this.product.warehousePromo.view.hasPercentage === "true") ||
        (this.isAuthenticated &&
          this.product &&
          this.product.priceStandardDisplay &&
          this.product.priceStandardDisplay > 0.01 &&
          this.product.warehousePromo &&
          this.product.warehousePromo.warehousePromoTypeId == 6 &&
          this.product.warehousePromo.view &&
          this.product.warehousePromo.view.hasPercentage === undefined &&
          this.product.warehousePromo.view.MPieces === undefined &&
          this.product.warehousePromo.view.percentagePrice != undefined);
      if (result !== true) {
        result = false;
      }
      return result;
    }
  },
  methods: {
    goToProductDetail() {
      this.$emit("goToProductDetail", this.product);
    }
  },
  data() {
    return {
      internalQuantity: -1
    };
  }
};
</script>
